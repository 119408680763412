export default {
    "en": {
        "action": {
            "add": "Ajouter",
            "created_at": "Date - Time",
            "details": "Details",
            "new": "Add action",
            "notify": "Notify by email",
            "silo": "Silo",
            "type": "Type",
            "type_description": "Label",
            "user": "User",
            "send_to": "Send to",
            "security_level": "Security level",
            "history_risks": "Risks"
        },
        "alert": {
            "created_at": "Created at",
            "category": "Category",
            "subject": "Subject",
            "sender": "Sender",
            "recipient": "Recipient",
            "actions": "Actions",
            "yes": "Yes",
            "no": "No",
            "deleted": "Alert deleted",
            "low": "Low",
            "normal": "Normal",
            "important": "Important",
            "critical": "Critical",
            "informations": "Information about",
            "leave": "Leave",
            "refresh": "Refresh",
            "siteIsMissingFromCompany": "Site missing",
            "duplicateSite": "New site",
            "type": "Type",
            "resolve_conflict": "Resolve conflict",
            "sure": "Are you sure ?",
            "irremediable": "This action is irremediable.",
            "cancel": "Cancel",
            "delete": "Delete",
            "sites": "Sites",
            "site": "Site",
            "resolve": "Resolve",
            "company": "CompanyEvent",
            "code": "Code",
            "name": "Name",
            "additional_name": "Additional name",
            "none": "None",
            "updated": "Alert updated",
            "city": "City",
            "contact": "Contact",
            "zip_code": "Zip code",
            "address": "Address",
            "site_already_exists": "Site already exists",
            "site_duplicate": "Duplicate site",
            "merge": "Merge",
            "create_like_new": "New site",
            "gps": "Coordinate",
            "comment": "Comment",
            "contact_avril": "Contact",
            "route_comments": "Route comment",
            "silos": "Silos",
            "silo": "Silo",
            "temporarySiloCreated": "Temporary silo created",
            "modify_silo_information": "Modify silo information",
            "associated_site": "Associated Site",
            "treated_silo": "Treated silo",
            "security_level": "Security level",
            "equipment": "Equipments",
            "accesses": "Vehicle accesses",
            "location_state": "Location State",
            "silo_type": "Silo type",
            "update": "Update",
            "silo_updated": "Silo updated",
            "manage": "Manage",
            "concerned_element": "Concerned element",
            "back": "Back",
            "go_to_alert_site": "Go to alert",
            "new_site": "New site",
            "reattach": "Reattach",
            "reattach_with_resembling_site": "Reattach with resembling site",
            "reattach_with_site": "Reattach with site",
            "search": "Search",
            "longitude": "Longitude",
            "latitude": "Latitude",
            "new_sites": "New sites",
            "all": "All",
            "farmer": "Ref EXT",
            "verification_silos": "Verification of silos",
            "security_alert_silos": "Security alert silos",
            "reported_hazards": "Reported hazards",
            "code_silo": "Silo code",
            "name_site": "Site name",
            "last_inventory_date": "Last inventory date",
            "go_to_silo": "Go to silo",
            "go_to_add_action": "Go to add an action",
            "status": "Status",
            "resolve_alert": "Resolve alert",
            "driver": "Chauffeur",
            "alert_detail": "Alert detail",
            "site_informations": "Site information",
            "empty": "-",
            "request_detail": "Request detail",
            "truck": "Truck",
            "detach": "Detach",
            "created": "Alert successfully created.",
            "site_name": "Site name",
            "is_treated": "Is treated ?",
            "risks": "Risks",
            "security": "Security",
            "send_an_update_request": "Send an update request",
            "update_request_sent": "Update request sent",
            "treat": "Treat",
            "picture": "Picture",
            "disable_qrcode_pairing": "Silo scan forbidden"
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "bo_parameters": {
            "step_delay_minute": "Operation delay exceeded (in minutes)",
            "actions": "Actions",
            "update_bo_parameters": "Update Back Office Parameters",
            "update": "Update",
            "cancel": "Cancel",
            "save": "Save",
            "edit_item": "Edit Item",
            "updated": "Item updated",
            "created": "Item created",
            "tracking_truck_purge_days": "Truck tracking data purge delay (in days)",
            "nfc_scan_logistic_areas": "List of areas supporting NFC tags",
            "rules": {
                "min": "Cannot be less than one day"
            }
        },
        "cartography": {
            "truck_code": "Truck code",
            "transition_date": "Transition date",
            "driver": "Driver name",
            "live": "Live",
            "tracking": "Tracking",
            "truck_choice": "Truck choice",
            "visualization": "Visualization",
            "continue": "Continue",
            "site_name": "Site name",
            "address": "City",
            "started_date": "Started date",
            "ended_date": "Ended date",
            "expected_date": "Planned date"
        },
        "cloud_messaging": {
            "lost_truck": "Lost truck",
            "truck_is_used_by": "Truck is used by {user_name}",
            "updated_step": "Step updated",
            "step_is_updated": "The step {step_id} has been updated",
            "deleted_step": "Step deleted",
            "step_is_deleted": "The step {step_id} has been deleted",
            "created_step": "Step created",
            "step_is_created": "The step {step_id} has been created",
            "update_thread": "Thread updated",
            "new_message": "New message"
        },
        "contribution": {
            "driver_title": "Driver contribution",
            "exploitant_title": "Exploitant contribution",
            "no_data": "No data"
        },
        "dashboard": {
            "action_to_perform": "Action to perform",
            "kpi_1": "KPI n1",
            "kpi_2": "KPI n2",
            "kpi_3": "KPI n3",
            "kpi_4": "KPI n4",
            "execution": "Execution",
            "administration": "Administration",
            "last_alerts": "Last alerts",
            "priority": "Priority",
            "date": "Date",
            "type": "Type",
            "sender": "Sender",
            "treaty": "Treaty",
            "important": "Important",
            "security": "Security",
            "execution_monitoring": "Execution monitoring",
            "percentage_of_silos_delivered_in_the_last_30_days": "Percentage of silos delivered in the last 30 days",
            "percentage_of_silos_by_security_level": "Percentage of silos by security level",
            "silos_delivered_registered": "Silos delivered registered",
            "silos_delivered_not_registered": "Silos delivered not registered",
            "green_silos": "Green silos",
            "orange_silos": "Orange silos",
            "red_silos": "Red silos",
            "black_silos": "Black silos",
            "new_black_silos": "New black silos",
            "new_red_silos": "New red silos",
            "new_orange_silos": "New orange silos",
            "see_all": "See all",
            "new_unknown_sites": "New unknown sites",
            "new_gps_points_to_locked": "New GPS points to locked",
            "new_client_informations": "New client informations",
            "reports_not_empty": "Reports not empty",
            "number_of_steps_completed": "Number of steps completed",
            "number_of_steps_uncompleted": "Number of steps uncompleted",
            "number_of_steps": "Number of steps",
            "report_percent": "Compte-rendus",
            "delivery_steps_percent": "Delivery steps with red and black silos",
            "number_of_delivery_steps_with_green_alert": "Delivery steps with green silos",
            "number_of_delivery_steps_with_orange_alert": "Delivery steps with orange silos",
            "number_of_delivery_steps_black_alert": "Delivery steps with black silos",
            "number_of_delivery_steps_red_alert": "Delivery steps with red silos",
            "number_of_delivery_steps": "Number of delivery steps",
            "next_week": "next week",
            "tomorrow": "tomorrow",
            "today": "today",
            "yesterday": "yesterday",
            "7_last_days": "7 last days",
            "last_week": "last week",
            "last_month": "last month",
            "12_last_months": "Last year",
            "transporters": "Transporters",
            "exposition": "Exposition",
            "inventory": "Inventory",
            "contributions": "Contributions",
            "contribution_driver": "Driver contribution",
            "contribution_exploit": "Operator contribution"
        },
        "driver": {
            "id": "#",
            "name": "Name",
            "email": "Email",
            "logistic_areas": "Logistic areas",
            "created_at": "Created at",
            "updated_at": "Updated at",
            "search": "Search",
            "new": "New",
            "actions": "Actions",
            "edit": "Edit",
            "cancel": "Cancel",
            "created": "Driver created",
            "deleted": "Driver deleted",
            "updated": "Driver updated",
            "delete": "Delete",
            "sure": "Are you sure ?",
            "irremediable": "This action is irremediable.",
            "trucks": "Trucks",
            "last_app_version": "Last app version",
            "last_used_truck": "Last used truck",
            "last_login_date": "Last login date",
            "expected_driver": "Expected driver"
        },
        "email_action_added": {
            "title": "[SANDRA V3] - ",
            "hello": "Hello,",
            "header": "A new action has been added to your SANDRA V3 Back-Office.",
            "go_to_site": "Access to Sandra",
            "aside": "If you are unable to click on the \"Access to Sandra\" button, copy and paste the URL below into your web browser: {link}",
            "regards": "Regards,",
            "site": "Site :",
            "date": "Date :",
            "label": "Label :",
            "silo": "Silo :",
            "details": "Details :"
        },
        "email_reset_password": {
            "title": "Reset Password Notification",
            "hello": "Hello !",
            "header": "Hello! You are receiving this email because we received a password reset request for your account.",
            "reset_password": "Reset password",
            "body": "This password reset link will expire in {minutes_expiration} minutes",
            "footer": "If you did not request a password reset, no further action is required.",
            "aside": "If you’re having trouble clicking the \"Reset password\" button, copy and paste the URL below into your web browser : {link}",
            "regards": "Regards,"
        },
        "exchange": {
            "clear_range_date": "Clear",
            "date_range": "Date range",
            "start_date": "Start date",
            "end_date": "End date",
            "type": "Type",
            "command": "Command",
            "finished_at": "Finished at",
            "tasks": "Tasks",
            "created_at": "Date",
            "method": "Method",
            "url": "URL",
            "payload": "Payload",
            "response": "Response",
            "duration": "Duration",
            "controller": "Controller",
            "action": "Action",
            "models": "Models",
            "ip": "IP Address",
            "return": "Return",
            "truck": "Truck",
            "tokens": "Tokens",
            "step_id": "Step",
            "is_sended": "Is sended ?",
            "notification": "Notifications"
        },
        "global": {
            "dashboard": "Dashboard",
            "tours": "Tours",
            "referential": "Referential",
            "sites": "Sites",
            "chat": "Chat",
            "administration": "Administration",
            "access_control": "Access control",
            "exchanges": "Exchanges",
            "settings": "Settings",
            "error": "Internal error server - Please retry",
            "no_data": "No data available",
            "no_results_text": "No data available",
            "loading_text": "Loading...",
            "select_all": "Select all"
        },
        "login": {
            "title": "Login",
            "email": "Email",
            "password": "Password",
            "remember_me": "Stay login",
            "get_started": "Get started",
            "logout": "Logout",
            "forget_password": "Forgot your password ?",
            "send_reset_password": "Send reset password",
            "email_sent": "Email sent",
            "password_confirmation": "Password confirmation",
            "reset_password": "Reset password",
            "close": "Close"
        },
        "logout": {
            "title": "Logout"
        },
        "maps": {
            "no_gps_coordinate": "No data",
            "change_gps_coordinates": "Change GPS coordinate",
            "are_you_sure_GPS_coordinates_change": "Are you sure the GPS coordinates change ?",
            "cancel": "cancel",
            "agree": "agree",
            "gps_coordinate": "GPS coordinates"
        },
        "messaging": {
            "threads": "Threads",
            "send": "Send",
            "message": "Message",
            "thread": "Thread",
            "user": "User",
            "users": "Users",
            "logistic_areas": "Logistic Areas"
        },
        "mobile_parameters": {
            "database_retention_time": "Database retention time",
            "message_retention_time": "Message retention time",
            "avril_finished_steps_max": "Avril finished steps max",
            "current_steps_before": "Current step before",
            "current_steps_after": "Current steps after",
            "last_silo_operations": "Last silo operations",
            "silo_photos": "Silo photos",
            "seconds_for_site_arrival": "Seconds for site arrival",
            "meters_for_site_arrival": "Meters for site arrival",
            "seconds_for_site_departure": "Seconds for site departure",
            "meters_for_site_departure": "Meters for site departure",
            "tracking_interval": "Tracking interval",
            "actions": "Actions",
            "update_mobile_parameters": "Update mobile parameters",
            "update": "Update",
            "cancel": "Cancel",
            "save": "Save",
            "edit_item": "Edit item",
            "updated": "Item updated",
            "created": "Item created"
        },
        "navigation": {
            "dashboard": "Dashboard",
            "tours": "Steps tracking",
            "sites": "Sites",
            "site": "Site",
            "silo": "Silo",
            "messaging": "Messaging",
            "administration": "Administration",
            "exchanges": "Exchanges",
            "profile": "Profile",
            "users": "Users",
            "drivers": "Drivers",
            "alerts": "Alerts",
            "repositories": "Repositories",
            "about": "about",
            "alert_sites": "Sites",
            "alert_silos": "Dangerous silos",
            "new_sites": "New sites",
            "silos": "Silos",
            "gps_coordinates": "GPS coordinates",
            "versions": "Versions APP",
            "verification_silos": "Verification of silos",
            "report": "Report",
            "sites_coordinates": "Sites coordinates",
            "mobile_parameters": "Mobile parameters",
            "cartography": "Cartography",
            "ras_reports": "No RAS reports",
            "non_ras_reports": "No RAS reports",
            "back_office_parameters": "Back Office parameters"
        },
        "order_line": {
            "order_id": "Order n°",
            "line_id": "Line n°",
            "silos": "Silos",
            "product": "Product",
            "delivery_date": "Planned delivery date",
            "real_delivery_date": "Real delivery date",
            "supplementation": "Supplementation",
            "loading_site": "Loading site",
            "product_comment": "Product comment",
            "product_ordered_quantity": "Ordered quantity",
            "product_loaded_quantity": "Loaded quantity",
            "equipment": "Equipment",
            "vehicle_access": "Vehicle access",
            "casing": "Casing",
            "expected_date": "Expected date",
            "presentation": "Presentation",
            "purge": "Purge",
            "packaging": "Packaging",
            "product_quantity_to_load": "Quantity to laod"
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "passwords": {
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "profile": {
            "email": "Email",
            "name": "Name",
            "password": "Actual password",
            "new_password": "New password",
            "new_password_confirmation": "Password confirmation",
            "update": "Update",
            "updated": "Profile updated"
        },
        "report": {
            "kpis": "KPIs",
            "reports": "Reports",
            "driver": "Driver",
            "truck": "Truck"
        },
        "repository": {
            "mobile_parameters": "Mobile parameters",
            "database_retention_time": "Database retention time",
            "message_retention_time": "Message retention time",
            "updated_at": "Updated at",
            "new": "New",
            "update": "Update",
            "cancel": "Cancel",
            "save": "Save",
            "new_item": "New item",
            "edit_item": "Edit item",
            "updated": "Item updated",
            "created": "Item created",
            "actions": "Actions",
            "deleted": "item deleted",
            "delete": "Delete",
            "sure": "Are you sure ?",
            "irremediable": "This action is irremediable.",
            "vehicle_accesses": "Vehicle accesses",
            "code": "Code",
            "name": "Name",
            "trucks": "Trucks",
            "logistic_areas": "Logistic areas",
            "logistic_area": "Logistic area",
            "time_slots": "Time slots",
            "silo_types": "Silo types",
            "logistic_manager": "Logistic manager",
            "quality_safety_manager": "Quality safety manager",
            "sandra_manager": "Sandra manager",
            "sales_manager": "Sales manager",
            "equipment": "Equipments",
            "categories": "Categories",
            "delivery_report_states": "Delivery report states",
            "loading_report_states": "Loading report states",
            "signature_states": "Signature states",
            "pictogram": "Pictogram",
            "select_file": "Select file",
            "risks": "Risks",
            "security_level": "Security level",
            "is_cumulative": "Cumulative",
            "instructions": "Instructions",
            "order": "Order",
            "label": "Label",
            "yes": "Yes",
            "no": "No",
            "priority": "Priority",
            "transporters": "Transporters",
            "transporter": "Transporter",
            "expected_drivers": "Expected drivers",
            "not_inventoryable_silos": "Not inventoryable silos",
            "driver": "driver",
            "vehicle_fleets": "Vehicle fleets",
            "vehicle_conditionnings": "Vehicle conditionnings",
            "conditioning": "Conditioning",
            "vehicle_fleet": "Vehicle fleet"
        },
        "role": {
            "admin": "Administrator",
            "web": "Web User",
            "driver": "Driver",
            "transporter": "Transporter"
        },
        "silo": {
            "id": "Code",
            "type": "Type",
            "production": "Production",
            "equipment": "Equipment",
            "vehicle_accesses": "Access",
            "building": "Building",
            "geolocation": "Geolocation",
            "last_inventory": "Dernier inventaire",
            "no_gps_coordinate": "No data",
            "go_to_silo": "Go to silo",
            "silo_updated": "The silo has successfully been updated",
            "unlock": "Unlock",
            "lock": "Lock",
            "none": "Undefined",
            "information": "Info silo",
            "orders_history": "Orders history",
            "security_history": "Security history",
            "actual_risks": "Current risks",
            "edit": "Edit",
            "instructions": "Instructions",
            "cancel": "Cancel",
            "no_risk": "No risk",
            "no_instruction": "No instruction",
            "no_comment": "No comment",
            "last_delivery_datetime": "Last delivery date",
            "security_level": "Security level",
            "comment": "Comment",
            "date": "Date",
            "history_risks": "Risks",
            "history_name": "Labels",
            "creation_date": "Creation date",
            "last_operation": "Last operation",
            "sales_representative": "Sales representative",
            "driver": "Driver",
            "vehicle": "Vehicle",
            "product": "Product",
            "delivered_quantity": "Delivered quantity",
            "loading_company": "Loading company",
            "code": "Id",
            "logistic_manager": "Logistic manager",
            "sandra_manager": "Sandra manager",
            "quality_safety_manager": "Quality Safety manager",
            "logistic_area": "Logistic area {id}",
            "pictures": "Pictures",
            "picture_date": "Date",
            "picture_name": "Name",
            "picture_comment": "Comment",
            "actions": "Actions",
            "add_picture": "Add picture",
            "edit_picture": "Edit picture",
            "order_id": "Order id",
            "status": "Status",
            "cr_status": "CR status",
            "cr_comment": "CR Cmm",
            "truck_code": "Truck code",
            "ask_picture_deletion": "Deletion request",
            "merge": "Merge silo",
            "merge_dialog_title": "Silo merge",
            "silo": "Silo",
            "validate": "Validate",
            "silos_merged": "Silos merged",
            "disable_qrcode_pairing": "Silo scan forbidden",
            "identification": "Identification"
        },
        "silo_alert_kpi": {
            "title": "Actions",
            "last_action": "Last action",
            "last_action_date_range": "Last action date range",
            "last_action_date": "Last action date",
            "last_delivery": "Last delivery",
            "ref_ext": "ref EXT",
            "last_12_months_exposition": "12 Months exposition"
        },
        "site": {
            "id": "Code",
            "location_state": "Location state",
            "city": "City",
            "address": "Address",
            "zip_code": "Zip code",
            "silo_number": "Silo number",
            "actions": "Actions",
            "logistic_area": "Logistic area",
            "new": "New",
            "search": "Search",
            "delete": "Delete the site",
            "update": "Update the site",
            "edit_site": "Edit the site",
            "edit": "Edit",
            "cancel": "Cancel",
            "code": "Code",
            "address_1": "Address n°1",
            "address_2": "Address n°2",
            "address_3": "Address n°3",
            "longitude": "Longitude",
            "latitude": "Latitude",
            "comments": "Comments",
            "route_comments": "Route comments",
            "contact_name": "Contact name",
            "contact_mobile": "Contact mobile",
            "site_updated": "The site has successfully been updated",
            "details": "Details",
            "silos": "Silos",
            "contacts": "Contacts",
            "security_level": "Security level",
            "equipment": "Equipement",
            "accesses": "Vehicle accesses",
            "silo_type": "Silo type",
            "category": "Category",
            "information": "Information about the site",
            "gps": "GPS",
            "name": "Label",
            "status": "Status",
            "active": "Active",
            "inactive": "Inactive",
            "locked": "Locked",
            "unlocked": "Unlocked",
            "state": "State",
            "last_operation": "last.op",
            "contact_information": "Contact information",
            "none": "None",
            "type": "Type",
            "last_delivery": "Last delivery",
            "last_loading": "Last loading",
            "last_checking": "Last checking",
            "no_data": "No data",
            "no_operation": "No operation",
            "mobile": "Mobile",
            "phone": "Phone",
            "mail": "Email",
            "company": "Company",
            "breeding": "Breeding",
            "factory": "Factory",
            "not_specified": "Not specified",
            "gps_coordinate": "GPS coordinate",
            "unlock": "Unlock",
            "lock": "Lock",
            "go_to_site": "Go to site",
            "see_site": "See",
            "back": "Back",
            "risks": "Risks",
            "site_references": "Ref EXT",
            "intern_site_reference": "Ref INT",
            "history": "History",
            "driver": "Driver",
            "vehicle": "Vehicle",
            "product": "Product",
            "delivered_quantity": "Delivered quantity",
            "loading_company": "Loading company",
            "date": "Date",
            "silo": "Silo",
            "order_id": "Order id",
            "comment": "Comment",
            "add_attachment": "Add a file",
            "edit_attachment": "Edit the file",
            "attachments": "Attachments",
            "attachment_date": "Date",
            "attachment_name": "Name",
            "cr_status": "CR status",
            "op_type": "Op Type",
            "cr_comment": "CR Cmm",
            "contact_telephone": "Contact phone",
            "reattached_sites": "Reattached sites",
            "success_detachment": "Successfully detached site",
            "create_alert": "Create alert with this site",
            "attention": "Attention",
            "confirmation_action": "Do you really want to perform this action?",
            "see_step_detail": "See step detail",
            "entry": "Entrée",
            "exit": "Sortie",
            "empty": "-",
            "contact": "Contact",
            "informations_about_the_site": "Information about the site",
            "coordinates": "Coordinates",
            "detail_of_the_request": "Detail of the request",
            "truck": "Truck",
            "sandra_user_approving_the_request": "Sandra user who is approving the request",
            "new_update_request_created": "A new update request has been created by a back office sandra user",
            "concerned_site": "Concerned site",
            "reference_breeder_gescom": "Ref EXT (breeder code Gescom)",
            "gps_coordinates": "GPS coordinates",
            "site_name": "Site name",
            "disable_qrcode_pairing": "Silo scans forbidden"
        },
        "tour": {
            "created_at": "Created at",
            "date_range": "Date range",
            "id": "Id",
            "driver": "Driver",
            "not_assigned": "Not assigned",
            "email": "Email",
            "logistic_areas": "Logistic areas",
            "logistic_area": "Logistic area",
            "date": "Date",
            "trucks": "Trucks",
            "code": "Code",
            "steps": "Steps",
            "status": "Status",
            "truck": "Truck",
            "no_code": "No code",
            "unknown": "Unknown",
            "apply": "Apply",
            "step_number": "Step number",
            "loaded": "Loaded",
            "deleted": "Deleted",
            "terminate": "Terminate",
            "available": "Available",
            "site": "Site",
            "sequence": "Sequence",
            "started": "Started",
            "not_started": "no started",
            "step_information": "Step information",
            "site_information": "Site information",
            "order_line_information": "Order line information",
            "operation_date": "Operation date",
            "type": "Type",
            "address": "Address",
            "name": "Name",
            "contacts": "Contacts",
            "comment": "Comment",
            "previous_step": "Previous step",
            "next_step": "Next step",
            "logistic_manager_phone": "Phone",
            "logistic_manager_mobile_phone": "Mobile",
            "logistic_manager_email": "Email",
            "quality_safety_manager_phone": "Phone",
            "quality_safety_manager_mobile_phone": "Mobile",
            "quality_safety_manager_email": "Email",
            "sandra_manager_phone": "Phone",
            "sandra_manager_mobile_phone": "Mobile",
            "sandra_manager_email": "Email",
            "expected_date": "Expected date",
            "effective_dates": "Effective dates",
            "driver_dates": "Driver dates",
            "entrance": "Entrance : ",
            "exit": "Exit : ",
            "driver_expected_date": "Driver expected date : ",
            "step_comment": "Step comment",
            "cr_details": "CR Detail",
            "cr_status": "CR Status",
            "delivery_date": "Delivery date",
            "time_slot": "Time slot",
            "delivery_date_2": "Delivery date n°2",
            "time_slot_2": "Time slot n°2",
            "empty": "-",
            "see_site": "See",
            "real_entrance_date": "Real entrance date",
            "real_exit_date": "Real exit date",
            "security_level": "Security level",
            "refresh": "Refresh",
            "not_ras": "Not RAS",
            "expected_driver": "Expected driver",
            "cr_step": "CR Step",
            "step_disinfection": "Disinfection",
            "step_washing": "Washing",
            "cr_date": "CR Validation date",
            "cr_approving_driver": "CR Approving driver",
            "signature": "Signature",
            "tour_comment": "Tour comment",
            "order_comment": "Order comment",
            "driver_comment": "Driver comment",
            "cleaning_inside": "Nettoyage intérieur",
            "cleaning_outside": "Nettoyage extérieur",
            "presentation": "Présentation"
        },
        "user": {
            "id": "#",
            "name": "Name",
            "email": "Email",
            "areas": "Areas",
            "created_at": "Created at",
            "updated_at": "Updated at",
            "search": "Search",
            "new": "New",
            "roles": "Roles",
            "actions": "Actions",
            "edit": "Edit",
            "cancel": "Cancel",
            "created": "User created",
            "deleted": "User deleted",
            "updated": "User updated",
            "delete": "Delete",
            "sure": "Are you sure ?",
            "irremediable": "This action is irremediable.",
            "last_app_version": "Last app version",
            "last_used_truck": "Last used truck",
            "last_login_date": "Last login date"
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "numeric": "The {attribute} must be greater than {value}.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "string": "The {attribute} must be greater than {value} characters.",
                "array": "The {attribute} must have more than {value} items."
            },
            "gte": {
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "string": "The {attribute} must be greater than or equal {value} characters.",
                "array": "The {attribute} must have {value} items or more."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lt": {
                "numeric": "The {attribute} must be less than {value}.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "string": "The {attribute} must be less than {value} characters.",
                "array": "The {attribute} must have less than {value} items."
            },
            "lte": {
                "numeric": "The {attribute} must be less than or equal {value}.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "string": "The {attribute} must be less than or equal {value} characters.",
                "array": "The {attribute} must not have more than {value} items."
            },
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "uuid": "The {attribute} must be a valid UUID.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": []
        },
        "version": {
            "actions": "Actions",
            "cancel": "Cancel",
            "comment": "Comment",
            "date": "Date",
            "delete": "Delete",
            "deleted": "The version has successfully been deleted",
            "edit": "Edit",
            "major": "Major",
            "no": "No",
            "new": "New",
            "number": "Number",
            "number_hint": "In the format (x.x.x)",
            "search": "Rechercher",
            "updated": "The version has successfully been updated",
            "yes": "Yes"
        }
    },
    "fr": {
        "action": {
            "add": "Ajouter",
            "created_at": "Date - Heure",
            "details": "Détails",
            "new": "Ajouter une action",
            "notify": "Notifier par email",
            "silo": "Silo",
            "type": "Type",
            "type_description": "Libellé",
            "user": "Créateur",
            "send_to": "Envoyé à",
            "security_level": "Niveau de sécurité",
            "history_risks": "Risques",
            "add_photos": "Ajouter des photos",
            "photo_limitation_rules": "Attention, les messageries limitent la taille des pièces jointes qu'elles peuvent recevoir. Veuillez ne pas dépasser 20 000 kilo-octets (20000 Ko).",
            "add_from_computer": "Ajouter depuis un pc",
            "add_from_sandra": "Ajouter depuis sandra",
            "photo_imported_from_computer": "Photos importées depuis l'ordinateur",
            "photo_retrieved_from_sandra": "Photos récupérées depuis sandra",
            "name": "Nom",
            "photo": "Photo",
            "photos": "Photos",
            "date": "Date",
            "comment": "Commentaire",
            "pick_photos_from_sandra": "Choisir des photos depuis Sandra",
            "empty": "vide"
        },
        "alert": {
            "created_at": "Créé le",
            "category": "Catégorie",
            "subject": "Sujet",
            "sender": "Expéditeur",
            "recipient": "Destinataire",
            "actions": "Actions",
            "yes": "Oui",
            "no": "Non",
            "deleted": "Alerte supprimée",
            "low": "Bas",
            "normal": "Normal",
            "important": "Important",
            "critical": "Critique",
            "informations": "Informations à propos de ",
            "leave": "Quitter",
            "refresh": "Rafraîchir",
            "siteIsMissingFromCompany": "Site manquant",
            "duplicateSite": "Nouveau site",
            "type": "Type",
            "company": "Société",
            "resolve_conflict": "Résoudre les conflits",
            "sure": "Êtes-vous sûr ?",
            "irremediable": "Cette action est irrémédiable.",
            "cancel": "Annuler",
            "delete": "Supprimer",
            "sites": "Sites",
            "site": "Site",
            "resolve": "Résoudre",
            "code": "Code",
            "name": "Nom",
            "additional_name": "Nom supplémentaire",
            "none": "Aucun",
            "updated": "Conflit résolu",
            "city": "Ville",
            "contact": "Contact",
            "zip_code": "Code postal",
            "address": "Adresse",
            "company_already_exists": "Société existante",
            "company_duplicate": "Société dupliquée",
            "site_already_exists": "Site existant",
            "site_duplicate": "Site dupliqué",
            "merge": "Fusionner les sites",
            "create_like_new": "Créer comme nouveau site",
            "gps": "Coordonnées",
            "comment": "Commentaire",
            "contact_avril": "Contact",
            "route_comments": "Commentaire route",
            "silos": "Silos",
            "silo": "Silo",
            "temporarySiloCreated": "Silo temporaire créé",
            "modify_silo_information": "Modifier les informations du silo",
            "associated_site": "Site associé",
            "treated_silo": "Silo traité",
            "security_level": "Niveau de sécurité",
            "equipment": "Équipement",
            "accesses": "Accès véhicule",
            "location_state": "Statut de géo-positionnement",
            "silo_type": "Type du silo",
            "update": "Mettre à jour",
            "silo_updated": "Silo mis à jour",
            "manage": "Gérer",
            "concerned_element": "Élément concerné",
            "back": "Retour",
            "go_to_alert_site": "Aller à l'alerte",
            "new_site": "Nouveau site",
            "reattach": "Rattacher",
            "reattach_with_resembling_site": "Rattacher avec un site semblable",
            "reattach_with_site": "Rattacher avec un site",
            "search": "Rechercher",
            "longitude": "Longitude",
            "latitude": "Latitude",
            "new_sites": "Nouveaux sites",
            "all": "Tous",
            "verification_silos": "Vérification des silos",
            "security_alert_silos": "Alertes silos dangereux",
            "reported_hazards": "Dangers signalés",
            "code_silo": "Code du silo",
            "name_site": "Nom du site",
            "last_inventory_date": "Date dernier inventaire",
            "go_to_silo": "Aller au silo",
            "go_to_add_action": "Ajouter une action",
            "farmer": "Ref EXT",
            "status": "Statut",
            "resolve_alert": "Clôturer l'alerte",
            "driver": "Chauffeur",
            "truck": "Camion",
            "alert_detail": "Détails de l'alerte",
            "site_informations": "Informations sur le site",
            "empty": "-",
            "request_detail": "Détail de la demande",
            "detach": "Détacher",
            "created": "Alerte créée avec succès.",
            "site_name": "Libellé site",
            "is_treated": "Est traitée ?",
            "risks": "Risques",
            "security": "Sécurité",
            "send_an_update_request": "Envoyer une demande de modification",
            "update_request_sent": "Demande de modification envoyée",
            "transmit_information": "Transmettre l'information",
            "treat": "Traiter",
            "picture": "Photo",
            "creator": "Créateur",
            "disable_qrcode_pairing": "Scan silo interdit"
        },
        "auth": {
            "failed": "Ces informations ne correspondent pas à nos enregistrements.",
            "throttle": "Trop de tentatives de connexion. Veuillez réessayer dans {seconds} secondes."
        },
        "bo_parameters": {
            "step_delay_minute": "Délai d'opération dépassé (en minutes)",
            "actions": "Actions",
            "update_bo_parameters": "Mise à jour des paramètres Back office",
            "update": "Mettre à jour",
            "cancel": "annuler",
            "save": "Sauvegarder",
            "edit_item": "Élément à éditer",
            "updated": "Élément mis à jour",
            "created": "Élément créé",
            "tracking_truck_purge_days": "Délai de purge de données du tracking camion (en jour)",
            "nfc_scan_logistic_areas": "Liste des zones supportant les tags NFC",
            "rules": {
                "min": "Ne peut pas être inférieur à un jour"
            }
        },
        "cartography": {
            "truck_code": "Code camion",
            "transition_date": "Date de passage",
            "driver": "Chauffeur",
            "live": "En direct",
            "tracking": "Tracking",
            "truck_choice": "Choix du camion",
            "visualization": "Visualisation",
            "continue": "Continuer",
            "site_name": "Site",
            "address": "Commune",
            "started_date": "Date d'entrée",
            "ended_date": "Date de sortie",
            "expected_date": "date plannifiée"
        },
        "cgu": {
            "edit_file": "Modifier le fichier",
            "select_file": "Selectionner un NOUVEAU fichier",
            "filename": "Nom du fichier actuel",
            "comment": "Commentaire",
            "save": "Enregistrer",
            "last_update": "Date de dernière mise à jour du fichier",
            "updated": "Fichier mis à jour"
        },
        "cloud_messaging": {
            "lost_truck": "Camion perdu",
            "truck_is_used_by": "Le camion est utilisé par {user_name}",
            "updated_step": "Étape modifiée",
            "step_is_updated": "L'étape {step_id} a été modifiée",
            "deleted_step": "Étape supprimée",
            "step_is_deleted": "L'étape {step_id} a été supprimée",
            "created_step": "Étape créée",
            "step_is_created": "L'étape {step_id} a été créée",
            "update_thread": "La discussion a été mise à jour",
            "new_thread": "Un nouvelle discussion a été créée",
            "new_message": "Nouveau message"
        },
        "contribution": {
            "driver_title": "Contribution chauffeur",
            "exploitant_title": "Contribution exploitant",
            "no_data": "Aucune données"
        },
        "dashboard": {
            "action_to_perform": "Action à réaliser",
            "kpi_1": "Action à faire",
            "kpi_2": "Comptes-rendus renseignés",
            "kpi_3": "Comptes-rendus incomplets",
            "kpi_4": "Clients non géopositionnés",
            "execution": "Exécution",
            "administration": "Administration",
            "last_alerts": "Dernières alertes",
            "priority": "Priorité",
            "date": "Date",
            "type": "Type",
            "sender": "Expéditeur",
            "treaty": "Traiter",
            "important": "Important",
            "security": "Sécurité",
            "execution_monitoring": "Suivi d'éxecution",
            "percentage_of_silos_delivered_in_the_last_30_days": "Pourcentage des silos livrés depuis les 30 derniers jours",
            "percentage_of_silos_by_security_level": "Pourcentage des silos par niveau de sécurité",
            "silos_delivered_registered": "Silos livrés renseignés",
            "silos_delivered_not_registered": "Silos livrés non renseignés",
            "green_silos": "Silos verts",
            "orange_silos": "Silos orange",
            "red_silos": "Silos rouges",
            "black_silos": "Silos noirs",
            "new_black_silos": "Nouveaux silos noirs",
            "new_red_silos": "Nouveaux silos rouges",
            "new_orange_silos": "Nouveaux silos oranges",
            "see_all": "Voir la liste complète",
            "new_unknown_sites": "Nouveaux sites inconnus",
            "new_gps_points_to_locked": "Nouveaux points GPS à verrouiller",
            "new_client_informations": "Nouvelles informations clients",
            "reports_not_empty": "Comptes-rendus non rien à signaler",
            "number_of_steps_completed": "CR validés",
            "number_of_steps_uncompleted": "CR non validés",
            "number_of_steps": "Nombre d'étape",
            "report_percent": "Compte-rendus",
            "delivery_steps_percent": "Silos",
            "number_of_delivery_steps_with_green_alert": " Livraisons sur silos verts",
            "number_of_delivery_steps_with_orange_alert": "Livraisons sur silos oranges",
            "number_of_delivery_steps_black_alert": "Livraisons sur silos noirs",
            "number_of_delivery_steps_red_alert": "Livraisons sur silos rouges",
            "number_of_delivery_steps_blue_alert": "Livraisons sur silos Non renseigné",
            "number_of_delivery_steps": "Livraisons sur les autres silos de couleurs",
            "next_week": "Semaine prochaine",
            "tomorrow": "Demain",
            "today": "Aujourd'hui",
            "yesterday": "Hier",
            "7_last_days": "7 derniers jours",
            "last_week": "Semaine dernière",
            "last_month": "Mois précédent",
            "12_last_months": "12 dernier mois",
            "transporters": "Transporteurs",
            "exposition": "Exposition",
            "inventory": "Inventaire",
            "contributions": "Contributions",
            "contribution_driver": "Contribution chauffeurs",
            "contribution_exploit": "Contribution exploitants",
            "last_actions_title": "Suivi des actions sur silos livrés durant les 12 derniers mois",
            "conditioning": "Conditionnement",
            "fleets": "Parc"
        },
        "document": {
            "edit_file": "Modifier le fichier",
            "select_file": "Selectionner un NOUVEAU fichier",
            "comment": "Commentaire",
            "save": "Enregistrer",
            "link": "Lien vers la ressource",
            "last_update": "Date de dernière mise à jour de la ressource",
            "updated": "Fichier mis à jour",
            "actif": "Actif",
            "must_be_read": "Lecture obligatoire",
            "current_file": "Fichier actuel",
            "delete_fail": "Echec de la suppression du fichier",
            "updated_success": "Mise à jour du fichier réussi",
            "update_has_fail": "Echec de la mise à jour du fichier",
            "name": "Nom"
        },
        "driver": {
            "id": "#",
            "name": "Nom",
            "email": "Mail",
            "logistic_areas": "Zones logistiques",
            "created_at": "Créé le",
            "updated_at": "Mis à jour le",
            "search": "Rechercher",
            "new": "Nouveau",
            "actions": "Actions",
            "edit": "Éditer",
            "cancel": "Annuler",
            "created": "Chauffeur créé",
            "deleted": "Chauffeur supprimé",
            "updated": "Chauffeur mis à jour",
            "delete": "Supprimer",
            "sure": "Êtes-vous sûr ?",
            "irremediable": "Cette action est irrémédiable.",
            "trucks": "Camions",
            "last_app_version": "Dernière version app",
            "last_login_date": "Date de dernière connexion",
            "last_used_truck": "Dernier camion utilisé",
            "expected_driver": "Chauffeur prévu",
            "transporters": "Transporteurs"
        },
        "email_action_added": {
            "title": "[SANDRA V3] - ",
            "hello": "Bonjour,",
            "header": "Une nouvelle action a été ajoutée sur votre Back-Office SANDRA V3.",
            "go_to_site": "Accéder à Sandra",
            "aside": "Si vous ne parvenez pas à cliquer sur le bouton \"Accéder à Sandra\", copiez et collez l’URL ci-dessous dans votre navigateur Web: {link}",
            "regards": "Cordialement,",
            "site": "Site concerné :",
            "date": "Date :",
            "label": "Libellé :",
            "silo": "Silo :",
            "details": "Détail :"
        },
        "email_duplicated_site_notification": {
            "subject": "Alerte de création d’un nouveau site",
            "hello": "Bonjour,",
            "header": "Une nouvelle alerte site a été créée sur votre Back-Office SANDRA V3.",
            "site": "Site concerné :",
            "date": "Date de création :",
            "button": "Voir l'alerte"
        },
        "email_reset_password": {
            "title": "Notification de la réinitialisation de mot de passe",
            "hello": "Bonjour !",
            "header": "Bonjour ! Vous recevez cet email car nous avons reçu une demande de réinitialisation du mot de passe pour votre compte.",
            "reset_password": "Réinitialiser le mot de passe",
            "body": "Ce lien de réinitialisation de mot de passe expirera dans {minutes_expiration} minutes",
            "footer": "Si vous n'avez pas demandé de réinitialisation de mot de passe, aucune action n'est requise.",
            "aside": "Si vous ne parvenez pas à cliquer sur le bouton \"Réinitialiser le mot de passe\", copiez et collez l’URL ci-dessous dans votre navigateur Web: {link}",
            "regards": "Cordialement,"
        },
        "exchange": {
            "clear_range_date": "Reset",
            "date_range": "Plage de date",
            "start_date": "Date de début",
            "end_date": "Date de fin",
            "command": "Commande",
            "finished_at": "Terminé le",
            "type": "Type",
            "tasks": "Tâches",
            "created_at": "Date",
            "method": "Méthode",
            "url": "Lien",
            "payload": "Payload",
            "response": "Réponse",
            "duration": "Durée",
            "controller": "Contrôleur",
            "action": "Action",
            "models": "Modèles",
            "ip": "Adresse IP",
            "return": "Retour",
            "truck": "Camion",
            "tokens": "Tokens",
            "step_id": "Étape",
            "is_sended": "Est envoyé ?",
            "notification": "Notifications"
        },
        "global": {
            "dashboard": "Tableaux de bord",
            "tours": "Tournées",
            "referential": "Référentiels",
            "sites": "Lieux",
            "chat": "Messagerie",
            "administration": "Administration",
            "access_control": "Contrôle d'accès",
            "exchanges": "Échanges",
            "settings": "Paramètres",
            "error": "Erreur du serveur - Veuillez réessayer",
            "no_data": "Aucune donnée",
            "no_results_text": "Aucune donnée",
            "loading_text": "Chargement...",
            "select_all": "Tout sélectionner",
            "rows_per_page": "Lignes par page"
        },
        "login": {
            "title": "Connexion",
            "email": "Mail",
            "password": "Mot de passe",
            "remember_me": "Rester connecté",
            "get_started": "Commencer",
            "logout": "Déconnexion",
            "forget_password": "Mot de passe oublié ?",
            "send_reset_password": "Envoyer la réinitialisation du mot de passe",
            "email_sent": "Mail envoyé",
            "password_confirmation": "Confirmation de mot de passe",
            "reset_password": "Réinitialiser le mot de passe",
            "close": "Fermer"
        },
        "logout": {
            "title": "Déconnexion"
        },
        "maps": {
            "no_gps_coordinate": "Aucune donnée",
            "change_gps_coordinates": "Changement de coordonnées GPS",
            "are_you_sure_GPS_coordinates_change": "Êtes-vous sûr du changement de coordonnées GPS ?",
            "cancel": "annuler",
            "agree": "accepter",
            "gps_coordinate": "Coordonnées GPS"
        },
        "messaging": {
            "threads": "Sujets",
            "send": "Envoyer",
            "message": "Message",
            "thread": "Sujet",
            "user": "Utilisateur",
            "users": "Utilisateurs",
            "logistic_areas": "Zones Logistiques"
        },
        "mobile_parameters": {
            "database_retention_time": "Temps de rétention de la base de données (en jours)",
            "message_retention_time": "Temps de rétention des messages (en jours)",
            "avril_finished_steps_max": "Nombre d'étapes maximum à afficher pour les dernières opérations",
            "current_steps_before": "Étapes à récupérer à afficher avant ce jour (en jours)",
            "current_steps_after": "Étapes à récupérer à afficher après ce jour (en jours)",
            "last_silo_operations": "Nombre d'éléments à afficher dans l'historique des livraisons du silo",
            "silo_photos": "Nombre de photos maximum par silo",
            "seconds_for_site_arrival": "Délai dans le rayon (en secondes) pour la détection automatique de l'arrivée sur site",
            "meters_for_site_arrival": "Rayon (en mètres) pour la détection automatique de l'arrivée sur site",
            "seconds_for_site_departure": "Délai hors du rayon (en secondes) pour la détection automatique de départ de site",
            "meters_for_site_departure": "Rayon (en mètres) pour la détection automatique de départ de sit",
            "tracking_interval": "Intervalle entre 2 remontées de coordonnées GPS du camion (en minutes)",
            "silo_link_required": "Appariement obligatoire",
            "scan_required": "Scan obligatoire",
            "disable_localisation_after_last_step_in_minutes": "Désactivation de la localisation X minutes après la dernière étape",
            "inventory_delay_exposition": "Délai d`expiration inventaire (en mois)",
            "actions": "Actions",
            "update_mobile_parameters": "Mise à jour des paramètres mobiles",
            "required_link_and_scan_logistic_area_ids": "Appariement et scans obligatoires",
            "required_link_and_scan_logistic_area_ids_form": "Zones logistiques concernées par cette obligation",
            "update": "Mettre à jour",
            "cancel": "annuler",
            "save": "Sauvegarder",
            "edit_item": "Élément à éditer",
            "updated": "Élément mis à jour",
            "created": "Élément créé"
        },
        "navigation": {
            "dashboard": "Tableaux de bord",
            "tours": "Suivi des étapes",
            "tours_tracking": "Suivi des tournées",
            "sites": "Sites",
            "site": "Site",
            "silo": "Silo",
            "cartography": "Cartographie",
            "messaging": "Messagerie",
            "administration": "Administration",
            "exchanges": "Échanges",
            "profile": "Profil",
            "users": "Utilisateurs",
            "drivers": "Chauffeurs",
            "transporters": "Transporteurs",
            "alerts": "Alertes",
            "repositories": "Référentiels",
            "about": "À propos",
            "alert_sites": "Sites",
            "alert_silos": "Silos dangereux",
            "new_sites": "Nouveaux sites",
            "silos": "Silos",
            "gps_coordinates": "Coordonnées GPS",
            "versions": "Versions APP",
            "verification_silos": "Vérification des silos",
            "report": "Compte-rendu",
            "sites_coordinates": "Coordonnées sites",
            "mobile_parameters": "Paramètres mobiles",
            "ras_reports": "CR non RAS",
            "non_ras_reports": "Comptes-rendus non RAS",
            "back_office_parameters": "Paramètres Back office",
            "cgu_localisation": "CGU coordonnées GPS",
            "documents": "Documents"
        },
        "order_line": {
            "order_id": "N° de commande",
            "line_id": "N° de ligne",
            "silos": "Silos",
            "product": "Produit",
            "delivery_date": "Date de livraison prévue",
            "real_delivery_date": "Date de livraison effective",
            "supplementation": "Supp",
            "loading_site": "Usine de départ",
            "product_comment": "Cmm Art",
            "product_ordered_quantity": "Quantité commandée",
            "product_loaded_quantity": "Quantité chargée",
            "equipment": "Équipement",
            "vehicle_access": "Accès véhicules",
            "casing": "Case",
            "expected_date": "Date prévue",
            "presentation": "Présentation",
            "purge": "Purge",
            "packaging": "Conditionnement",
            "product_quantity_to_load": "Quantité à charger"
        },
        "pagination": {
            "previous": "&laquo; Précédent",
            "next": "Suivant &raquo;"
        },
        "passwords": {
            "reset": "Mot de passe réinitialisé !",
            "sent": "Un mail contenant un lien de réinitialisation de mot de passe a été envoyé !",
            "token": "Le token n'est pas valide.",
            "user": "Aucun utilisateur correspond à ce mail."
        },
        "profile": {
            "email": "Mail",
            "name": "Nom",
            "password": "Mot de passe actuel",
            "new_password": "Nouveau mot de passe",
            "new_password_confirmation": "Confirmation du nouveau mot de passe",
            "update": "Mettre à jour",
            "updated": "Mis à jour"
        },
        "report": {
            "kpis": "KPIs",
            "reports": "Compte-rendus",
            "driver": "Chauffeur",
            "truck": "Camion"
        },
        "repository": {
            "mobile_parameters": "Paramètres mobiles",
            "database_retention_time": "Temps de rétention de la base de données (en jours)",
            "message_retention_time": "Temps de rétention des messages (en jours)",
            "updated_at": "Mis à jour le",
            "new": "Nouveau",
            "update": "Mettre à jour",
            "cancel": "annuler",
            "save": "Sauvegarder",
            "new_item": "Nouvel élément",
            "edit_item": "Élément à éditer",
            "updated": "Élément mis à jour",
            "created": "Élément créé",
            "actions": "Actions",
            "deleted": "Élément supprimé",
            "sure": "Êtes-vous sûr ?",
            "irremediable": "Cette action est irréversible.",
            "delete": "Supprimer",
            "vehicle_accesses": "Accès véhicules",
            "code": "Code",
            "name": "Nom",
            "trucks": "Camions",
            "logistic_areas": "Zones logistiques",
            "logistic_area": "Zone logistique",
            "time_slots": "Tranche de temps",
            "silo_types": "Type de silos",
            "logistic_manager": "Responsable logistique",
            "quality_safety_manager": "Responsable contrôle/qualité",
            "sandra_manager": "Référent ZL",
            "sales_manager": "Référent ADV",
            "equipment": "Équipements",
            "categories": "Catégories",
            "delivery_report_states": "État du rapport de livraison",
            "loading_report_states": "État du rapport de chargement",
            "signature_states": "État des signatures",
            "pictogram": "Icône",
            "select_file": "Sélectionner un fichier",
            "risks": "Risques",
            "security_level": "Niveau de sécurité",
            "is_cumulative": "Cumulable",
            "instructions": "Consignes",
            "order": "Ordre d'affichage",
            "label": "Libellé",
            "yes": "Oui",
            "no": "Non",
            "priority": "Prioritaire",
            "transporters": "Transporteurs",
            "transporter": "Transporteur",
            "expected_drivers": "Chauffeurs prévus",
            "not_inventoryable_silos": "Silos non inventoriables",
            "driver": "Chauffeur",
            "vehicle_fleets": "Parcs",
            "vehicle_conditionnings": "Conditionnements véhicules",
            "conditioning": "Conditionnement",
            "vehicle_fleet": "Parc",
            "cleaning_inside": "Nettoyages Intérieurs",
            "cleaning_outside": "Nettoyages Extérieurs",
            "biosecurities": "Contextes de Bio-sécurité",
            "logo": "Logo",
            "level": "Niveau",
            "pdf_file": "Document PDF",
            "see_pdf_file": "Voir le document PDF"
        },
        "role": {
            "admin": "Admin",
            "web": "Web",
            "driver": "Chauffeur",
            "transporter": "Transporteur"
        },
        "silo": {
            "id": "Code",
            "type": "Type",
            "production": "Production",
            "equipment": "Équipement",
            "vehicle_accesses": "Accès",
            "building": "Bâtiment",
            "geolocation": "Coordonnées GPS",
            "last_inventory": "Dernier inventaire",
            "no_gps_coordinate": "Aucune donnée",
            "go_to_silo": "Aller à la page silo",
            "silo_updated": "Le silo a été mis à jour avec succès",
            "unlock": "Déverrouiller",
            "lock": "Verrouiller",
            "none": "Non renseigné",
            "information": "Info silo",
            "orders_history": "Historique commandes",
            "security_history": "Action sécurité",
            "actual_risks": "Risques actuels",
            "edit": "Modifier",
            "instructions": "Consignes",
            "cancel": "Annuler",
            "no_risk": "Aucun risque",
            "no_instruction": "Aucune consigne",
            "no_comment": "Aucun commentaire",
            "last_delivery_datetime": "Dernière livraison",
            "security_level": "Niveau de sécurité",
            "comment": "Commentaire",
            "date": "Date",
            "history_risks": "Risques",
            "history_name": "Libellé",
            "creation_date": "Date de création",
            "last_operation": "Dernière opération",
            "driver": "Chauffeur",
            "vehicle": "Véh.",
            "product": "Produit",
            "delivered_quantity": "Qté Livrée",
            "loading_company": "Usine de départ",
            "code": "Code",
            "identification": "Identification",
            "logistic_area": "Zone logistique {id}",
            "pictures": "Photos",
            "picture_date": "Date",
            "picture_name": "Nom",
            "picture_comment": "Commentaire",
            "actions": "Actions",
            "add_picture": "Ajouter une photo",
            "edit_picture": "Modifier la photo",
            "order_id": "N° Commande",
            "status": "Statut",
            "cr_status": "Statut CR",
            "cr_comment": "Cmm CR",
            "truck_code": "Code camion",
            "ask_picture_deletion": "Demande de suppression",
            "merge": "Fusionner le silo",
            "merge_dialog_title": "Fusion du silo",
            "silo": "Silo",
            "validate": "Valider",
            "silos_merged": "Silos fusionnés",
            "disable_qrcode_pairing": "Scan silo interdit"
        },
        "silo_alert_kpi": {
            "title": "Liste des silos",
            "last_action": "Dernière action",
            "last_action_date_range": "Plage de date dernière Livraison",
            "last_delivery_range": "Plage de date de livraison",
            "last_action_date": "Date dernière action",
            "last_delivery": "Dernière livraison",
            "ref_ext": "ref EXT",
            "last_12_months_exposition": "NB Exposition 12 mois"
        },
        "site": {
            "id": "Code",
            "location_state": "GPS",
            "city": "Ville",
            "address": "Adresse",
            "zip_code": "Code postal",
            "silo_number": "Nombre de silo",
            "actions": "Actions",
            "logistic_area": "Zone logistique",
            "new": "Nouveau",
            "search": "Rechercher",
            "delete": "Supprimer le site",
            "update": "Mettre à jour le site",
            "edit_site": "Éditer le site",
            "edit": "Modifier",
            "cancel": "Annuler",
            "code": "Code",
            "address_1": "Adresse 1",
            "address_2": "Adresse 2",
            "address_3": "Adresse 3",
            "longitude": "Longitude",
            "latitude": "Latitude",
            "comments": "Commentaire site",
            "route_comments": "Commentaire itinéraire",
            "contact_name": "Nom du contact",
            "contact_mobile": "Mobile du contact",
            "site_updated": "Le site a été mis à jour avec succès",
            "details": "Détails",
            "silos": "Silos",
            "contacts": "Contacts",
            "security_level": "Niveau de sécurité",
            "equipment": "Équipement",
            "accesses": "Accès véhicule",
            "silo_type": "Type du silo",
            "category": "Catégorie",
            "information": "Informations à propos du site",
            "gps": "GPS",
            "name": "Libellé",
            "status": "Statut",
            "active": "Actif",
            "inactive": "Inactif",
            "locked": "Vérrouillé",
            "unlocked": "Déverrouillé",
            "state": "État",
            "type": "Type",
            "last_operation": "Dernière opération",
            "contact_information": "Coordonnées",
            "none": "Non renseignée",
            "last_delivery": "Dernière livraison",
            "last_loading": "Dernier chargement",
            "last_checking": "Dernière révision",
            "no_data": "Non renseigné",
            "no_operation": "Aucune opération",
            "mobile": "Téléphone mobile",
            "phone": "Téléphone",
            "mail": "Mail",
            "company": "Société",
            "breeding": "Élevage",
            "factory": "Usine",
            "not_specified": "Non renseigné",
            "gps_coordinate": "Coordonées GPS",
            "unlock": "Déverrouiller",
            "lock": "Verrouiller",
            "go_to_site": "Aller à la page site",
            "see_site": "Voir le site",
            "back": "Retour",
            "risks": "Risques",
            "site_references": "Ref EXT",
            "intern_site_reference": "Ref INT",
            "history": "Historique",
            "driver": "Chauffeur",
            "vehicle": "Véh.",
            "product": "Produit",
            "delivered_quantity": "Qté Livrée",
            "loading_company": "Usine de départ",
            "date": "Date",
            "silo": "Silo",
            "order_id": "N° Commande",
            "comment": "Commentaire",
            "add_attachment": "Ajouter un fichier",
            "edit_attachment": "Modifier le fichier",
            "attachments": "Pièces jointes",
            "attachment_date": "Date",
            "attachment_name": "Nom",
            "cr_status": "Statut CR",
            "op_type": "Type Op",
            "cr_comment": "Cmm CR",
            "contact_telephone": "Téléphone du contact",
            "reattached_sites": "Sites rattachés",
            "success_detachment": "Site détaché avec succès",
            "create_alert": "Créer une alerte à partir de ce site",
            "attention": "Attention",
            "confirmation_action": "Souhaitez-vous vraiment réaliser cette action ?",
            "see_step_detail": "Visualiser détail étape",
            "entry": "Entrée",
            "exit": "Sortie",
            "empty": "-",
            "exposition_count": "Nb expositions -12M",
            "last_security_action": "Dernière action",
            "contact": "Contact",
            "informations_about_the_site": "Information sur le site",
            "coordinates": "Coordonnées",
            "detail_of_the_request": "Détails de la demande : ",
            "truck": "Camion",
            "sandra_user_approving_the_request": "Utilisateur Sandra approbateur de la demande",
            "new_update_request_created": "Une nouvelle demande de mise à jour des coordonnées d’un site a été créée sur le Back Office SANDRA V3.",
            "concerned_site": "Site concerné : ",
            "reference_breeder_gescom": "Ref EXT (code éleveur Gescom)",
            "gps_coordinates": "Coordonnées GPS",
            "site_name": "Nom du site",
            "qrc": "Qrc ",
            "disable_qrcode_pairing": "Scan silos interdit",
            "public_health_alert": "Alerte Sanitaire"
        },
        "tour": {
            "created_at": "Crée le",
            "date_range": "Plage de dates",
            "id": "Numéro",
            "driver": "Chauffeur",
            "not_assigned": "Non assigné",
            "email": "Mail",
            "logistic_areas": "Zones logistiques",
            "logistic_area": "Zone logistique",
            "date": "Date",
            "trucks": "Camions",
            "code": "Code",
            "steps": "Étapes",
            "status": "Statut",
            "truck": "Véhicule",
            "no_code": "Aucun code",
            "unknown": "Inconnu",
            "apply": "Appliquer",
            "step_number": "Nombre d'étapes",
            "loaded": "Chargée",
            "deleted": "Supprimée",
            "terminate": "Terminée",
            "available": "Disponible",
            "site": "Site",
            "sequence": "Ordre",
            "started": "Démarré",
            "not_started": "Non démarré",
            "step_information": "Informations de l'étape",
            "site_information": "Informations du site",
            "order_line_information": "Informations de la ligne de commande",
            "operation_date": "Date de l'opération",
            "type": "Type",
            "address": "Adresse",
            "name": "Nom",
            "contacts": "Contacts",
            "comment": "Commentaire",
            "previous_step": "Étape précédente",
            "next_step": "Étape suivante",
            "logistic_manager_phone": "Téléphone",
            "logistic_manager_mobile_phone": "Mobile",
            "logistic_manager_email": "Email",
            "quality_safety_manager_phone": "Téléphone",
            "quality_safety_manager_mobile_phone": "Mobile",
            "quality_safety_manager_email": "Email",
            "sandra_manager_phone": "Téléphone",
            "sandra_manager_mobile_phone": "Mobile",
            "sandra_manager_email": "Email",
            "expected_date": "Date prévue",
            "effective_dates": "Dates saisies",
            "driver_dates": "Dates captées",
            "entrance": "Entrée : ",
            "exit": "Sortie : ",
            "driver_expected_date": "Date prévue par le chauffeur : ",
            "step_comment": "Commentaire de l'étape",
            "cr_details": "Détails du CR",
            "cr_status": "Statut CR",
            "delivery_date": "Date de livraison",
            "time_slot": "Créneaux horaires",
            "delivery_date_2": "Date de livraison n°2",
            "time_slot_2": "Créneaux horaires n°2",
            "empty": "-",
            "see_site": "Voir le site",
            "real_entrance_date": "Date d'entrée réelle",
            "real_exit_date": "Date de sortie réelle",
            "security_level": "Niveau de sécurité",
            "refresh": "Actualiser",
            "not_ras": "Non RAS",
            "expected_driver": "Conducteur prévu",
            "cr_step": "CR de l'étape",
            "step_disinfection": "Désinfection",
            "step_washing": "Lavage",
            "cr_date": "Date validation du CR",
            "cr_approving_driver": "Chauffeur approbateur du CR",
            "signature": "Signature",
            "tour_comment": "Commentaire tournée",
            "order_comment": "Commentaire commande",
            "driver_comment": "Commentaire chauffeur",
            "sanitary_context": "Contexte sanitaire",
            "expected_starting_date": "Date début prévue",
            "expected_real_date": "Date début réelle",
            "car": "Camion",
            "tour": "Tournée",
            "cistern_number_plate": "Immat. citerne",
            "km_written": "Kms saisies / tournée",
            "tour_duration_written": "Durée saisie tournée",
            "to_load": "À charger",
            "loaded_quantity": "Quantité chargée",
            "nb_lots": "NB lots",
            "city": "Commune",
            "security": "Sécurité / Alerte Sanitaire",
            "inside_cleaning": "Nettoyage interne",
            "outside_cleaning": "Nettoyage externe",
            "report_status": "CR Complet / Incomplet",
            "status_cr": "CR RAS / NON RAS",
            "detail": "Détail",
            "order": "Commande",
            "product": "Produit",
            "mix": "Mel lig.",
            "conditioning": "Conditionnement",
            "factory": "Usine",
            "case": "Case",
            "silo": "Silo",
            "comm_art": "Com art.",
            "access": "Accès",
            "equipment": "Équipement",
            "risk": "Risque",
            "flash_qr_code": "Flash code",
            "datetime_flash": "Date heure flash",
            "purge": "Purge",
            "export_results": "Exporter les résultats",
            "close": "Fermer",
            "purge_datetime": "Date heure purge"
        },
        "transporter": {
            "transporters": "Transporteurs",
            "transporters_default": "Transporteur prévu",
            "new": "Transporteur",
            "created": "Transporteur créé",
            "search": "Recherche"
        },
        "user": {
            "id": "#",
            "name": "Nom",
            "email": "Mail",
            "areas": "Zones",
            "created_at": "Créé le",
            "updated_at": "Mis à jour le",
            "search": "Rechercher",
            "new": "Nouveau",
            "roles": "Rôles",
            "actions": "Actions",
            "edit": "Éditer",
            "cancel": "Annuler",
            "created": "Utilisateur créé",
            "deleted": "Utilisateur supprimé",
            "updated": "Utilisateur mis à jour",
            "delete": "Supprimer",
            "sure": "Êtes-vous sûr ?",
            "irremediable": "Cette action est irrémédiable.",
            "last_app_version": "Dernière version app",
            "last_login_date": "Date de dernière connexion",
            "last_used_truck": "Dernier camion utilisé"
        },
        "validation": {
            "accepted": "Le champ {attribute} doit être accepté.",
            "active_url": "Le champ {attribute} n'est pas une URL valide.",
            "after": "Le champ {attribute} doit être une date postérieure au {date}.",
            "after_or_equal": "Le champ {attribute} doit être une date postérieure ou égale au {date}.",
            "alpha": "Le champ {attribute} doit contenir uniquement des lettres.",
            "alpha_dash": "Le champ {attribute} doit contenir uniquement des lettres, des chiffres et des tirets.",
            "alpha_num": "Le champ {attribute} doit contenir uniquement des chiffres et des lettres.",
            "array": "Le champ {attribute} doit être un tableau.",
            "before": "Le champ {attribute} doit être une date antérieure au {date}.",
            "before_or_equal": "Le champ {attribute} doit être une date antérieure ou égale au {date}.",
            "between": {
                "numeric": "La valeur de {attribute} doit être comprise entre {min} et {max}.",
                "file": "La taille du fichier de {attribute} doit être comprise entre {min} et {max} kilo-octets.",
                "string": "Le texte {attribute} doit contenir entre {min} et {max} caractères.",
                "array": "Le tableau {attribute} doit contenir entre {min} et {max} éléments."
            },
            "boolean": "Le champ {attribute} doit être vrai ou faux.",
            "confirmed": "Le champ de confirmation {attribute} ne correspond pas.",
            "date": "Le champ {attribute} n'est pas une date valide.",
            "date_equals": "Le champ {attribute} doit être une date égale à {date}.",
            "date_format": "Le champ {attribute} ne correspond pas au format {format}.",
            "different": "Les champs {attribute} et {other} doivent être différents.",
            "digits": "Le champ {attribute} doit contenir {digits} chiffres.",
            "digits_between": "Le champ {attribute} doit contenir entre {min} et {max} chiffres.",
            "dimensions": "La taille de l'image {attribute} n'est pas conforme.",
            "distinct": "Le champ {attribute} a une valeur en double.",
            "email": "Le champ {attribute} doit être une adresse email valide.",
            "ends_with": "Le champ {attribute} doit se terminer par une des valeurs suivantes : {values}",
            "exists": "Le champ {attribute} sélectionné est invalide.",
            "file": "Le champ {attribute} doit être un fichier.",
            "filled": "Le champ {attribute} doit avoir une valeur.",
            "gt": {
                "numeric": "La valeur de {attribute} doit être supérieure à {value}.",
                "file": "La taille du fichier de {attribute} doit être supérieure à {value} kilo-octets.",
                "string": "Le texte {attribute} doit contenir plus de {value} caractères.",
                "array": "Le tableau {attribute} doit contenir plus de {value} éléments."
            },
            "gte": {
                "numeric": "La valeur de {attribute} doit être supérieure ou égale à {value}.",
                "file": "La taille du fichier de {attribute} doit être supérieure ou égale à {value} kilo-octets.",
                "string": "Le texte {attribute} doit contenir au moins {value} caractères.",
                "array": "Le tableau {attribute} doit contenir au moins {value} éléments."
            },
            "image": "Le champ {attribute} doit être une image.",
            "in": "Le champ {attribute} est invalide.",
            "in_array": "Le champ {attribute} n'existe pas dans {other}.",
            "integer": "Le champ {attribute} doit être un entier.",
            "ip": "Le champ {attribute} doit être une adresse IP valide.",
            "ipv4": "Le champ {attribute} doit être une adresse IPv4 valide.",
            "ipv6": "Le champ {attribute} doit être une adresse IPv6 valide.",
            "json": "Le champ {attribute} doit être un document JSON valide.",
            "lt": {
                "numeric": "La valeur de {attribute} doit être inférieure à {value}.",
                "file": "La taille du fichier de {attribute} doit être inférieure à {value} kilo-octets.",
                "string": "Le texte {attribute} doit contenir moins de {value} caractères.",
                "array": "Le tableau {attribute} doit contenir moins de {value} éléments."
            },
            "lte": {
                "numeric": "La valeur de {attribute} doit être inférieure ou égale à {value}.",
                "file": "La taille du fichier de {attribute} doit être inférieure ou égale à {value} kilo-octets.",
                "string": "Le texte {attribute} doit contenir au plus {value} caractères.",
                "array": "Le tableau {attribute} doit contenir au plus {value} éléments."
            },
            "max": {
                "numeric": "La valeur de {attribute} ne peut être supérieure à {max}.",
                "file": "La taille du fichier de {attribute} ne peut pas dépasser {max} kilo-octets.",
                "string": "Le texte de {attribute} ne peut contenir plus de {max} caractères.",
                "array": "Le tableau {attribute} ne peut contenir plus de {max} éléments."
            },
            "mimes": "Le champ {attribute} doit être un fichier de type : {values}.",
            "mimetypes": "Le champ {attribute} doit être un fichier de type : {values}.",
            "min": {
                "numeric": "La valeur de {attribute} doit être supérieure ou égale à {min}.",
                "file": "La taille du fichier de {attribute} doit être supérieure à {min} kilo-octets.",
                "string": "Le texte {attribute} doit contenir au moins {min} caractères.",
                "array": "Le tableau {attribute} doit contenir au moins {min} éléments."
            },
            "not_in": "Le champ {attribute} sélectionné n'est pas valide.",
            "not_regex": "Le format du champ {attribute} n'est pas valide.",
            "numeric": "Le champ {attribute} doit contenir un nombre.",
            "password": "Le mot de passe est incorrect",
            "present": "Le champ {attribute} doit être présent.",
            "regex": "Le format du champ {attribute} est invalide.",
            "required": "Le champ {attribute} est obligatoire.",
            "required_if": "Le champ {attribute} est obligatoire quand la valeur de {other} est {value}.",
            "required_unless": "Le champ {attribute} est obligatoire sauf si {other} est {values}.",
            "required_with": "Le champ {attribute} est obligatoire quand {values} est présent.",
            "required_with_all": "Le champ {attribute} est obligatoire quand {values} sont présents.",
            "required_without": "Le champ {attribute} est obligatoire quand {values} n'est pas présent.",
            "required_without_all": "Le champ {attribute} est requis quand aucun de {values} n'est présent.",
            "same": "Les champs {attribute} et {other} doivent être identiques.",
            "size": {
                "numeric": "La valeur de {attribute} doit être {size}.",
                "file": "La taille du fichier de {attribute} doit être de {size} kilo-octets.",
                "string": "Le texte de {attribute} doit contenir {size} caractères.",
                "array": "Le tableau {attribute} doit contenir {size} éléments."
            },
            "starts_with": "Le champ {attribute} doit commencer avec une des valeurs suivantes : {values}",
            "string": "Le champ {attribute} doit être une chaîne de caractères.",
            "timezone": "Le champ {attribute} doit être un fuseau horaire valide.",
            "unique": "La valeur du champ {attribute} est déjà utilisée.",
            "uploaded": "Le fichier du champ {attribute} n'a pu être téléversé.",
            "url": "Le format de l'URL de {attribute} n'est pas valide.",
            "uuid": "Le champ {attribute} doit être un UUID valide",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "name": "nom",
                "username": "nom d'utilisateur",
                "email": "adresse email",
                "first_name": "prénom",
                "last_name": "nom",
                "password": "mot de passe",
                "password_confirmation": "confirmation du mot de passe",
                "city": "ville",
                "country": "pays",
                "address": "adresse",
                "phone": "téléphone",
                "mobile": "portable",
                "age": "âge",
                "sex": "sexe",
                "gender": "genre",
                "day": "jour",
                "month": "mois",
                "year": "année",
                "hour": "heure",
                "minute": "minute",
                "second": "seconde",
                "title": "titre",
                "content": "contenu",
                "description": "description",
                "excerpt": "extrait",
                "date": "date",
                "time": "heure",
                "available": "disponible",
                "size": "taille"
            }
        },
        "version": {
            "actions": "Actions",
            "cancel": "Annuler",
            "comment": "Commentaire",
            "date": "Date",
            "delete": "Supprimer",
            "deleted": "La version a été supprimée avec succès",
            "edit": "Modifier",
            "major": "Majeur",
            "no": "Non",
            "new": "Nouveau",
            "number": "Numéro",
            "number_hint": "Sous la forme (x.x.x)",
            "search": "Rechercher",
            "updated": "La version a été mise à jour avec succès",
            "yes": "Oui"
        }
    }
}